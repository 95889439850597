import "./index.scss";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
const App = lazy(() => import("./app/App"));

ReactDOM.render(
  <Suspense
    fallback={() => {
      return null;
    }}
  >
    <App />
  </Suspense>,
  document.getElementById("root")
);
